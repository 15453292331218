const preprints_manuscripts = [
  {
    year: "(in review)",
    title: "Representation of Bias in Regression Algorithms.",
    author_span: <span>Hanyi Luo, Rin Ha, Subi Lumala, <b>Amani Maina-Kilaas</b>, Olivia Yin, Aitong Zhong, and George Monta&ntilde;ez</span>
  },
  {
    year: 2023,
    title: "Preconditioned Visual Language Inference with Weak Supervision.",
    author_span: <span>Ehsan Qasemi, <b>Amani Maina-Kilaas</b>, Devadutta Dash, Khalid Alsaggaf, and Muhao Chen</span>,
    url: "https://doi.org/10.48550/arXiv.2306.01753",
  },
]

const conference_proceedings = [
  {
    year: "2024",
    title: "Learning Phonotactics from Linguistic Informants.",
    author_span: <span>Canaan Breiss<sup>&dagger;</sup>, Alexis Ross<sup>&dagger;</sup>, <b>Amani Maina-Kilaas</b>, Roger Levy, and Jacob Andreas</span>,
    pdf: "linguistic-informants.pdf",
    url: "https://aclanthology.org/2024.scil-1.3/",
  },
  {
    year: 2023,
    title: "An Interpretable Joint Nonnegative Matrix Factorization-Based Point Cloud Distance Measure.",
    author_span: <span>Hannah Friedman, <b>Amani Maina-Kilaas</b>, Julianna Schalkwyk, Hina Ahmed and Jamie Haddock</span>,
    location: "57th Annual Conference on Information Sciences and Systems",
    pdf: "jnmf-distance-measure.pdf",
    url: "https://www.doi.org/10.1109/CISS56502.2023.10089765",
    code: "https://github.com/hannahfriedman/Joint_NMF_Similarity_Measure_and_Method",
  },
  {
    year: 2021,
    title: "The Hero's Dilemma: Survival Advantages of Intention Perception in Virtual Agent Games.",
    author_span: <span><b>Amani Maina-Kilaas</b>, George Monta&ntilde;ez, Cynthia Hom, Kevin Ginta, and Cindy Lay</span>,
    location: "2021 IEEE Conference on Games",
    pdf: "the-heros-dilemma.pdf",
    url: "https://www.doi.org/10.1109/CoG52621.2021.9619071",
    code: "https://github.com/AMISTAD-lab/heros-dilemma-source",
  },
  {
    year: 2021,
    title: "The Predator's Purpose: Intention Perception in Simulated Agent Environments.",
    author_span: <span><b>Amani Maina-Kilaas</b>, Cynthia Hom, Kevin Ginta, and George Monta&ntilde;ez</span>,
    location: "2021 IEEE Congress on Evolutionary Computation",
    pdf: "the-predators-purpose.pdf",
    url: "https://www.doi.org/10.1109/CEC45853.2021.9504885",
    code: "https://github.com/AMISTAD-lab/predators-purpose-source",
  },
  {
    year: 2021,
    title: "The Gopher's Gambit: Survival Advantages of Artifact-Based Intention Perception.",
    author_span: <span>Cynthia Hom<sup>&dagger;</sup>, <b>Amani Maina-Kilaas<sup>&dagger;</sup></b>, Kevin Ginta, Cindy Lay, and George Monta&ntilde;ez</span>,
    location: "13th International Conference on Agents and Artificial Intelligence",
    pdf: "the-gophers-gambit.pdf",
    url: "https://www.doi.org/10.5220/0010207502050215",
    code: "https://github.com/AMISTAD-lab/gophers-gambit-source",
  },
];

export const pub_entries = [
  ["Conference Proceedings", conference_proceedings],
  ["Preprints & Manuscripts", preprints_manuscripts],
];